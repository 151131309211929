import {GLOBAL_DATA} from "../constants";
import {v4 as uuidv4} from "uuid";
import {DataCollector} from "../Data/DataCollector";
import {Logger} from "../Logger/Logger";
import {DataStorage} from "../DeviceStorage/DataStorage";

const storage = DataStorage.getInstance();
const logger = Logger.getInstance();

const generateClientId = () => {
    let clientId = storage.readAppData(GLOBAL_DATA.CLIENT_ID)
    if(!clientId){
        clientId = uuidv4();
        storage.writeAppData(GLOBAL_DATA.CLIENT_ID, clientId);
    }

    DataCollector.storeClientId(clientId);

    logger.logClientId();
}

export {generateClientId};