import { existsClean } from '../Utils/existsClean';
import {GLOBAL_DATA} from "../constants";
import {parseUserAgent} from "../Utils/parseUserAgent";

const DataCollector = (() => {
  let geoData = {};
  let errors = [];
  let loadedTime = Date.now();
  let clientId = GLOBAL_DATA.NOT_SET;
  let sessionId = GLOBAL_DATA.NOT_SET;
  let userAgentDetails = parseUserAgent();

  const storeGeo = (data) => {
    let temporaryGeoData = {};

    for (const key of Object.keys(data)) {
      const newKey = key.replace('geoplugin_', '');
      temporaryGeoData[newKey] = data[key];
    }

    geoData.ip = existsClean(temporaryGeoData, 'request');
    geoData.latitude = existsClean(temporaryGeoData, 'latitude');
    geoData.longitude = existsClean(temporaryGeoData, 'longitude');
    geoData.city = existsClean(temporaryGeoData, 'city');
    geoData.continent = existsClean(temporaryGeoData, 'continentCode');
    geoData.region = existsClean(temporaryGeoData, 'regionCode');
    geoData.timezone = existsClean(temporaryGeoData, 'timezone');
  };


  const storeError = (error) => {
    errors.push(error);
  };

  const storeClientId = (pClientId) => {
    clientId = pClientId;
  };

  const storeSessionId = (pSessionId) => {
    sessionId = pSessionId;
  };

  const getGeoData = () => geoData;

  const getClientId = () => clientId;

  const getUserAgentDetails = () => userAgentDetails;

  const getSessionId = () => sessionId;

  return {
    storeGeo,
    getGeoData,
    getLoadedTime: () => loadedTime,
    storeError,
    storeClientId,
    getUserAgentDetails,
    getClientId,
    storeSessionId,
    getSessionId
  };
})();

export { DataCollector };
