const GLOBAL_DATA = {
    USE_REMOTE_DEBUGGER: "useRemoteDebugger",
    NOT_SET: "NOT_SET",
    CLIENT_ID: "clientId",
    SESSIONID: "sessionId"
}

const CONFIG = {
    [GLOBAL_DATA.USE_REMOTE_DEBUGGER]: true
}

export { GLOBAL_DATA, CONFIG };
