const removeCookie = (name) => {
    const yesterday = new Date();
    yesterday.setTime(yesterday.getTime() - 1 * 24 * 60 * 60 * 1000);
    writeCookie(name, '', yesterday, true);
    writeCookie(name, '', yesterday, false);
};

const removeAllCookies = (except) => {
    const cookies = document.cookie.split('; ');
    for (const i in cookies) {
        const cookieName = cookies[i].split('=')[0];
        if (except.includes(cookieName)) {
            continue;
        }
        removeCookie(cookieName);
    }
};

const readCookie = (name)=> {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];

        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }

    return null;
};

// TODO REMOVE RAZVAN AND REPLACE WITH THE ACTUAL DOMAIN
const cookieDomain = process.env.NODE_ENV === 'development' ? location.hostname : 'razvan';

const writeCookie = (name, value, expiryDate, useDomain = true) => {
    const expires = '; expires=' + expiryDate.toUTCString();
    const domain = useDomain ? '; domain=' + cookieDomain : '';

    document.cookie = name + '=' + value + expires + domain + '; path=/;';
};

const getAllCookies = () => {
    const cookieArray = document.cookie
        .split(';')
        .map((cookiePairString) => cookiePairString.trim().split('='))
    const cookieMap = new Map(cookieArray);
    return cookieMap;
};

export { removeCookie, removeAllCookies, readCookie, writeCookie, getAllCookies };
