import { DataCollector } from '../Data/DataCollector';
import { EventBus } from '../Events/EventBus';
import { EVENTS } from '../Events/Events';

const Auth = () => {
    const bodyObject = {
        clientId: DataCollector.getClientId(),
        applicationVersion: "1.0.0",
        hasRun: false,
        userAgent: DataCollector.getUserAgentDetails().ua,
        locationInfo: "full json response from geo api",
        isHD: false,
        NID: "NETWORK ID",
        SID: "SERVICE ID",
        timestamp: Date.now(), // unix epoch
    };

    const sampleResponse = {
        clientId: "sample_id",
        sessionId: "session_id",
        capabilityRestrict: "bit values..."
    }

    console.log('RZV --- ');
    console.log('RZV --- ');
    console.log('RZV --- bodyObject: -->', bodyObject);
    console.log('RZV --- bodyObject: -->', JSON.stringify(bodyObject));
    console.log('RZV --- ');
    console.log('RZV --- ');
    //fetch(' https://dtt.ocelot.tv:4433/api/Capabilities/device-info?clientId=client_id_sample&sessionId=session_id_sample',{
    //return fetch('http://81.149.180.21:2048/2015-03-31/functions/function/invocations',{
    //  fetch('http://10.0.20.237:9000/2015-03-31/functions/function/invocations', {
        fetch('https://kuqvdv54z8.execute-api.us-east-1.amazonaws.com/testStage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...bodyObject})
        }).then((response) => {
            console.log('RZV ---response: --> ', response);
            return response.json();
        })
        .then((data) => {
            console.log('RZV ---  response as json -> ', data );
            EventBus.emit(EVENTS.AUTH.AUTH_SUCCESS, { message: data });
        })
        .catch((error) => {
            EventBus.emit(EVENTS.AUTH.AUTH_FAIL, { message: error });

        });

//
//     const xhr = new XMLHttpRequest();
//     xhr.open('POST', 'http://10.0.20.237:9000/2015-03-31/functions/function/invocations');
//     xhr.setRequestHeader("Accept", "no-cors");
//    // xhr.setRequestHeader("Content-Type", "application/json");
//     xhr.onload = () => console.log(JSON.parse(xhr.responseText));
//     xhr.send(JSON.stringify({...bodyObject}));

};



export { Auth };




// fetchGeoData()
//   .then(() => {
//     EventBus.emit(EVENTS.AUTH.AUTH_SUCCESS, { event: 'aaaa' });
//   })
//   .catch((error) => {
//    EventBus.emit(EVENTS.AUTH.AUTH_FAIL, { event: error });
//  });


//
//
// const fetchGeoData = () =>
//     fetch('http://www.geoplugin.net/json.gp')
//         .then((response) => response.json())
//         .then((data) => {
//             DataCollector.storeGeo(data);
//         })
//         .catch((error) => {
//             console.error('Error fetching data:', error);
//         });
