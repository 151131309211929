import '../css/global.css';

import { Auth } from './Auth/Auth';
import { DataCollector } from './Data/DataCollector';
import { EventBus } from './Events/EventBus';
import { EVENTS } from './Events/Events';
import {DataStorage} from "./DeviceStorage/DataStorage";
import {log} from "./Utils/remoteLog";
import {GLOBAL_DATA} from "./constants";
import {GlobalData} from "./Data/GlobalData";
import { v4 as uuidv4 } from 'uuid';
import {Logger} from "./Logger/Logger";
import {generateClientId} from "./Utils/generateClientId";

const storage = DataStorage.getInstance();
const logger = Logger.getInstance();

document.addEventListener('DOMContentLoaded', async () => {
  logger.logSessionStarted();
  generateClientId();

  EventBus.on(EVENTS.AUTH.AUTH_SUCCESS, (event) => AuthSuccess(event));
  EventBus.on(EVENTS.AUTH.AUTH_FAIL, (event) => AuthError(event));

  Auth();
});

const AuthSuccess = (event) => {
  removeAuthEvents();
  showApplication();

  console.log('RZV --- auth ok', event);
};

const AuthError =  (event) =>{
  removeAuthEvents();
 console.log('RZV ---zzz ', JSON.stringify(event?.message?.message) +
   JSON.stringify(event?.message?.stack));
  console.log('RZV --- auth fail ', event);
}

const removeAuthEvents = () => {
  EventBus.off(EVENTS.AUTH.AUTH_SUCCESS, (event) => AuthSuccess(event));
  EventBus.off(EVENTS.AUTH.AUTH_FAIL, (event) => AuthError(event));
}

const showApplication = () => {
  try {
    const appManager = document.getElementById('applicationManager');
    const appObject = appManager.getOwnerApplication(document);
    if (appObject === null) {
      fatalError('ERROR acquiring the application object !');
    } else {
      appObject.show();
    }
  }
  catch (e) {
    fatalError('NOT an hbbtv client !');
  }
}

const fatalError = () => {
  console.log(' !!! FATAL ERROR !!! ');
}





//
// // setTimeout(() => {
//   //   throw new Error('RZV --- AuthSuccess error');
//   // }, 500);
// let capa = document.getElementById('oipfCapabilities');
// //log(`xmlCapabilities ${capa.xmlCapabilities}` );
// // console.log('RZV ---  ', capa.extraSDVideoDecodes );
// // console.log('RZV ---  ', capa.extraHDVideoDecodes );
// // console.log('RZV ---  ', capa.hasCapability('OITF_HD_UIPROF') );
// // console.log('RZV ---  ', capa );
// console.log('RZV --- oipfConfiguration ', window.oipfConfiguration );