import { CONFIG, GLOBAL_DATA } from './../constants';

const GlobalData = (() => {
    let settings = {
        [GLOBAL_DATA.USE_REMOTE_DEBUGGER]: CONFIG[GLOBAL_DATA.USE_REMOTE_DEBUGGER]
    };

    const getSetting = (key) => settings[key];

    const setSetting = (key, value) => {
        settings[key] = value;
    };

    return {
        getSetting,
        setSetting
    };
})();

export { GlobalData };
