const EventBusBuilder = (function () {
  let instance;

  const getInstance = () => {
    if (!instance) {
      let events = {};

      instance = {
        on: (event, listener) => {
          if (!events[event]) {
            events[event] = [];
          }
          if (!events[event].includes(listener)) {
            events[event].push(listener);
          }
        },

        off: (event) => {
          if (!events[event]) {
            console.warn(`Attempting to remove non-existent event: ${event}`);
            return;
          }
          delete events[event];
        },

        emit: (event, data) => {
          if (!events[event]) {
            console.warn(`Emitting event '${event}' that has no listeners.`);
            return;
          }
          for (const listener of events[event]) listener(data);
        }
      };
    }
    return instance;
  };

  return { getInstance };
})();

const EventBus = EventBusBuilder.getInstance();

export { EventBus };
