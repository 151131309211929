import {
    getAllLocalStorageData,
    readFromLocalStorage,
    removeEverythingFromLocalStorage,
    removeFromLocalStorage,
    writeToLocalStorage
} from "./localstorage";
import {getAllCookies, readCookie, removeAllCookies, removeCookie, writeCookie} from "./cookiestorage";

const DataStorage = (() => {
    let instance;

    const createInstance = () => {
        const writeAppData = (name, value, days = 365 * 5) => {
            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + days * 24 * 60 * 60 * 1000);

            writeCookie(name, value.toString(), expiryDate);
            writeToLocalStorage(name, value.toString(), expiryDate);
        };

        const readAppData = (name) => {
            return readFromLocalStorage(name) || readCookie(name);
        };


        const eraseAppData = (name) => {
            removeCookie(name);
            removeFromLocalStorage(name);
        };


        const eraseAllAppData = () => {
            removeAllCookies([]);
            removeEverythingFromLocalStorage([]);
        };


        const getAllAppData = () => {
            const cookieMap = getAllCookies();
            const localStorageMap = getAllLocalStorageData();
            return new Map([...Array.from(cookieMap.entries()), ...Array.from(localStorageMap.entries())]);
        };

        return {
            readAppData,
            writeAppData,
            eraseAppData,
            eraseAllAppData,
            getAllAppData
        };
    };

    const getInstance = () => {
        if (!instance) {
            instance = createInstance();
        }
        return instance;
    };

    return {
        getInstance
    };
})();

export { DataStorage };
