import {GlobalData} from "../Data/GlobalData";
import {GLOBAL_DATA} from "../constants";
import {delay} from "./delay";

const remoteQueueLogs = [];
let permitSend = true;
let remoteSendTimeout = null;

const log = (message, shouldDelete = false) => {
    console.log('RZV ---  ', message );

    if(!GlobalData.getSetting(GLOBAL_DATA.USE_REMOTE_DEBUGGER)) return;
    addToRemoteQueue(message, shouldDelete);
}

const sendRemoteLog = async () => {
    if (remoteQueueLogs.length === 0) return;

    await delay(300);

    if (permitSend === false) return;

    await actuallySendLog(remoteQueueLogs[0]);
}

const actuallySendLog = async (data) => {
    try {
        await fetch('https://razvanagache.com/test.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        console.log('Log sent:', JSON.stringify(data));
    } catch (e) {
        console.error('Error sending log:', e);
    } finally {
        remoteQueueLogs.shift();
        await sendRemoteLog();
    }
}

const addToRemoteQueue = (message, shouldDelete) => {
    permitSend = false;

    //const today = new Date();
    const time = new Date().toISOString()// `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}:${today.getMilliseconds()}`
    const logEntry = {
        timestamp: time,
        message: message
    };

    shouldDelete && (logEntry.delete = true);

    remoteQueueLogs.push(logEntry);
    remoteSendTimeout && clearTimeout(remoteSendTimeout);
    remoteSendTimeout = setTimeout(() => {
        permitSend = true;
        sendRemoteLog().then(r => {}).catch(e => {});
    }, 1000);
}

export { log };
