const parseUserAgent = () => {
    const uaString = navigator.userAgent.toLowerCase();
    console.log('RZV --- uaString ', uaString);

    console.log('RZV --- ', window.navigator.storage.estimate().then(estimate => console.log(estimate)));

    const deviceInfo = {
        hbbtvVersion: null,
        brand: null,
        model: null,
        modelYear: null,
        isHbbTV: false,
        ua: uaString
    };

    const hbbtvVersionMatch = uaString.match(/hbbtv\/([1-9]\.[0-9]{1,2}(?:\.[0-9]{1})?)/i);
    if (hbbtvVersionMatch) {
        deviceInfo.hbbtvVersion = hbbtvVersionMatch[1];
        deviceInfo.isHbbTV = true;
    }

    const brandModelPatterns = [
        {brand: 'panasonic', modelRegex: /panasonic;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'samsung', modelRegex: /samsung;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'lg', modelRegex: /lg;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'sony', modelRegex: /sony;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'philips', modelRegex: /philips;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'sharp', modelRegex: /sharp;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'toshiba', modelRegex: /toshiba;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'hisense', modelRegex: /hisense;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'grundig', modelRegex: /grundig;([^;]+)/i, yearRegex: /20\d{2}/},
        {brand: 'hitachi', modelRegex: /hitachi;([^;]+)/i, yearRegex: /20\d{2}/},
    ];

    brandModelPatterns.forEach(pattern => {
        if (uaString.includes(pattern.brand)) {
            const modelMatch = uaString.match(pattern.modelRegex);
            const yearMatch = uaString.match(pattern.yearRegex);
            if (modelMatch && modelMatch[1]) {
                deviceInfo.brand = pattern.brand;
                deviceInfo.model = modelMatch[1].trim();
            }
            if (yearMatch) {
                deviceInfo.modelYear = yearMatch[0].trim();
            }
        }
    });

    if (!deviceInfo.model && deviceInfo.brand) {
        const genericModelMatch = uaString.match(/;([^;]+);/);
        if (genericModelMatch && genericModelMatch[1]) {
            deviceInfo.model = genericModelMatch[1].trim();
        }
    }

    return deviceInfo;
}

export { parseUserAgent };
