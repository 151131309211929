const LOCAL_STORAGE_EXPIRY_TS_POSTFIX = '_exp';

const removeFromLocalStorage = (name) => {
    try {
        localStorage.removeItem(name);
        localStorage.removeItem(name + LOCAL_STORAGE_EXPIRY_TS_POSTFIX);
    } catch (e) {
       console.log(e, 'WARNING');
    }
};

const removeEverythingFromLocalStorage = (except) => {
    try {
        for (const key in localStorage) {
            if (
                except.includes(key) ||
                except.includes(key.substring(0, key.length - LOCAL_STORAGE_EXPIRY_TS_POSTFIX.length))
            ) {
                continue;
            }
            delete localStorage[key];
        }
    } catch (e) {
        console.log(e, 'WARNING');
    }
};

const readFromLocalStorage = (name) => {
    try {
        const localStorageExpire = localStorage.getItem(name + LOCAL_STORAGE_EXPIRY_TS_POSTFIX);
        if (localStorageExpire && parseInt(localStorageExpire) > 0 && parseInt(localStorageExpire) < Date.now()) {
            removeFromLocalStorage(name);
            return '';
        } else {
            return localStorage.getItem(name);
        }
    } catch (e) {
        console.log(e, 'WARNING');
        return null;
    }
};

const writeToLocalStorage = (name, value, expiryDate) => {
    try {
        localStorage.setItem(name, value);
        localStorage.setItem(name + LOCAL_STORAGE_EXPIRY_TS_POSTFIX, expiryDate.getTime().toString());
    } catch (e) {
        console.log(e, 'WARNING');
    }
};

const getAllLocalStorageData = () => {
    const localStorageMap = new Map();
    try {
        Object.keys(localStorage).forEach(function (key) {
            localStorageMap.set(key, localStorage.getItem(key));
        });
    } catch (e) {
        console.log(e, 'WARNING');
    }
    return localStorageMap;
};

export {
    removeFromLocalStorage,
    removeEverythingFromLocalStorage,
    readFromLocalStorage,
    writeToLocalStorage,
    getAllLocalStorageData,
};
