import {log} from "../Utils/remoteLog";
import {DataCollector} from "../Data/DataCollector";

const Logger = (() => {
    let instance;

    const createInstance = () => {
        const logSessionStarted = () =>{
            log("logger -> NEW SESSION STARTED at: " + DataCollector.getLoadedTime(), true);
        }

        const logClientId = () =>{
            log('logger -> clientId: ' + DataCollector.getClientId());

        }

        return {
            logSessionStarted,
            logClientId
        };
    };

    const getInstance = () => {
        if (!instance) {
            instance = createInstance();
        }
        return instance;
    };

    return {
        getInstance
    };
})();

export { Logger };
